.blogs {
    max-width: 70%;
    margin: 50px auto;
}
.cards {
    display: grid;
    grid-template-columns: 30% 30% 30%;
    justify-content: space-between;
    grid-row-gap: 30px;
    align-items: stretch;
    margin: 50px 0;
    margin-bottom: 150px;
}

.card {
    cursor: pointer;
    border-radius: 12px;
    outline: none;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
    height: 100%;
}
.card .title {
    padding: 10px;
    color: white;
    border-radius: 0px 0px 12px 12px;
    
}
.card img {
    display: block;
    width: 100%;
    object-fit: cover;
    height: 220px;
    border-radius: 12px 12px 0 0;
}
.card h3 {
    font-weight: 600;
    margin-bottom: 2px;
    font-size: 1.1rem;
    color: #000;

}
.card h4 {
    font-size: 0.8rem;
    font-weight: normal;
    color: #a4a4a4;
}
.c {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
}
.bookmark {
    width: 20px;
}



.search {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
    background-color: #fff;
    display: flex;
    border-radius: 8px;
}
.search input {
    
    height: 45px;
    outline: none;
    border: none;
    width: 100%;
    padding: 0px 20px;
    font-size: 1.1rem;
    border-radius: 8px 0px 0px 8px !important;
}
.search button {
    border-radius: 0px 8px 8px 0px;
    padding: 0px 18px;
    height: 45px;
    border: none;
    background-color: #4DD5CB;
}
.search button img {
    height: 20px;
    width: 20px;
    object-fit: contain;
}

@media (max-width:600px) {
    .blogs {
        max-width: 90%;
        margin: 30px auto !important;
    }
    .cards {
        grid-template-columns: 100%;
        grid-row-gap: 20px;
        margin: 30px 0;
        margin-bottom: 50px;
    }
    .card img {
        height: 200px;
    }
    .card h3 {
        font-size: 1.3rem;
    
    }
    .card h4 {
        font-size: 1rem;
    }
}
