.screen {
    height: 100px;
    width: 100%;
    margin-bottom: 10px;
    padding: 0 10px;
    background-color: #4357692d;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: white;
    font-weight: bold;
    box-sizing: border-box;
  }