.popup-box .wrapper {
    width: 280px;
    height: 310px;
    padding: 10px;
    border-radius: 10px;
    background-color: #e8f5fc;
  }
  .popup-box .screen{
    height: 60px;
  }
  .popup-box{
    top: auto;
    bottom: 61px;
  }
  .popup-box .box{
    overflow: visible;
    margin-top: 0;
    border: 2px solid rgb(31 41 55 / var(--tw-border-opacity));
    border-right: none;
    border-radius: 0px;
    border-bottom: none;
  }
  .popup-box .close-icon{
   position: absolute;
   top: -10px;
   left: -10px;
  }

  .popup-box .calBtn{
    background: #425062;;
  }
  .popup-box .calBtn:hover{
    background: #6366f1;;
  }