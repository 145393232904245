.container {
    max-width: 85%;
    margin: 80px auto;
    display: grid;
    grid-template-columns: 30% 30% 30%;
    grid-row-gap: 3rem;
    justify-content: space-between;
    align-items: stretch;
}
.card img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    margin-bottom: 14px;
    border-radius: 12px 12px 0 0;
}
.card {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    border-radius: 12px;
    position: relative;
}
.line {
    height: 2px;
    background-color: #eeeeee;
    width: 100%;
    margin: 12px 0;
}
.heart {
    position: absolute;
    top: 0px;
    right: 0px;
    cursor: pointer;
    background-color: rgba(0,0,0,0.7);
    padding: 10px;
    border-radius: 0 12px 0 30px;
}
.videoPlayer {
    max-width: 80%;
    margin: 0px auto;
    margin-top: 60px;
    display: grid;
    grid-template-columns: 70% 28%;
    justify-content: space-between;
    align-items: flex-start;
}
.videoPlayer p, .videoPlayer h2 {
    text-align: left;
}
.videoPlayer video {
    border-radius: 8px;
}
.videoPlayer h2 {
    font-size: 1.2rem;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 16px;
}

@media (max-width: 800px){
    .container {
        max-width: 90%;
        grid-template-columns: 48% 48%;
    }
}


@media (max-width: 600px){
    .container {
        max-width: 90%;
        grid-template-columns: 100%;
    }
    .videoPlayer {
        max-width: 90%;
        grid-template-columns: 100%;
    }
    .videoPlayer video {
        margin-bottom: 20px;
    }
}

